import {Components, Functions} from 'eyecons-components'
import React, {useContext, useEffect, useRef, useState} from "react";
import {Request} from 'eyecons-requests'
import {AppContext} from "src/layouts";
import Firebase from "src/clients/Firebase";

type ChatMessageProps = {
    username: string
    currentUsername: string | undefined
    time: string
    message: string
}

function ChatMessage({username, currentUsername, time, message}: ChatMessageProps) {
    const isSender = username === currentUsername

    return (
        <div className={'mb-3'}>
            <Components.Texts.Small className={`text-gray-400 mb-1 font-bold w-2/3 ${isSender ? 'ml-auto' : 'mr-0'}`}>
                {Functions.dateToTime((new Date(Number(time))))} - {username}
            </Components.Texts.Small>
            <p className={`p-2 rounded-lg w-2/3 ${isSender ? 'text-white bg-bright-blue-200 ml-auto' : 'bg-gray-100 mr-0'}`}>{message}</p>
        </div>
    )
}

type ChatInputProps = {
    sendMessage: (message: string) => void
}

function ChatInput({sendMessage}: ChatInputProps) {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [value, setValue] = useState<string>('')
    const [message, setMessage] = useState<boolean>(true)

    const onSendMessage = () => {
        if (value && message) {

            const noCharacter = value.match('[^a-zA-Z0-9]')

            if (noCharacter !== null) {
                sendMessage(value)
                setMessage(false)
            } else {
                sendMessage(filterInput(value))
                setMessage(false)
            }

            setValue('')
        }

        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus()
            }
            setMessage(true)
        }, 500)

    }

    const filterInput = (value: string) => {
        // TODO filter input
        return value
    }

    const sendOnEnter = (key: string) => {
        if (key === 'Enter') {
            onSendMessage()
        }
    }

    return <div className={'w-full p-5 rounded bg-white'}>
        <Components.Forms.Field>
            <input
                className={'p-2.5 border border-solid border-gray-300 rounded-md w-full pb-[47px]'}
                value={value}
                onChange={event => setValue(event.target.value)}
                onKeyDown={event => sendOnEnter(event.key)}
                placeholder="Typ hier een bericht.."
                ref={inputRef}
            />
        </Components.Forms.Field>
        <Components.Buttons.Button
            type={'primary'}
            onClick={onSendMessage}
            className={'w-full'}
        >
            Versturen
        </Components.Buttons.Button>
    </div>
}

function SaveUser() {
    const {authenticated, mutateAuthenticated} = useContext(AppContext)

    const [update, setUpdate] = useState({
        name: authenticated?.name
    })

    function handleSubmit() {
        Request.fromApi('auth').put('/api/user', update).then(response => {
            if (!response.errors) {
                mutateAuthenticated()
            }
        })
    }

    return <div className={'absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 flex flex-col gap-2'}>
        <input
            className={'px-5 py-3.5 border border-[#10083E] rounded-md bg-white'}
            placeholder={'Voeg naam toe'}
            value={update.name}
            onChange={event => setUpdate({name: event.target.value})}
        />
        <Components.Buttons.Button
            type={'primary'}
            onClick={() => handleSubmit()}
        >
            Verzend
        </Components.Buttons.Button>
    </div>
}

type VideoChatProps = {
    table: string
}

type Message = {
    key: string
    time: string
    username: string
    message: string
}

export function VideoChat({table}: VideoChatProps) {
    const {authenticated} = useContext(AppContext)
    const messageEnd = useRef<HTMLDivElement | null>(null)
    const [messages, setMessages] = useState<Message[]>([])
    const firebase = new Firebase()

    useEffect(() => {
        firebase.index(table, setMessages)

        return () => firebase.off(table)
    }, [])

    useEffect(() => {
        scrollToBottom()
    }, [messages, authenticated?.name])

    useEffect(() => {
        scrollToBottom()
    })

    function sendMessage(message: string) {
        if (authenticated) {
            firebase.store(table, authenticated, {message})
        }
    }

    function scrollToBottom() {
        messageEnd.current?.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'})
    }

    const hasUser = Boolean(authenticated?.name)

    return (
        <>
            <div className={'p-4 flex-1 overflow-scroll max-h-[250px] sm:max-h-[500px] md:max-h-[330px] lg:max-h-[420px] xl:max-h-[580px] sm:pb-[180px]'}>
                {
                    hasUser && messages.map((msg, index) => {
                        return (
                            <React.Fragment key={index}>
                                <ChatMessage
                                    time={msg.time}
                                    username={msg.username}
                                    message={msg.message}
                                    currentUsername={authenticated?.name}/>
                                {index === messages.length && <div ref={messageEnd}/>}
                            </React.Fragment>
                        )
                    })
                }
            </div>
            <div className={'sm:absolute sm:left-0 sm:right-0 sm:bottom-0'}>
                {hasUser && <ChatInput sendMessage={sendMessage}/>}
            </div>
            {!hasUser && <SaveUser/>}
            {/*{!hasUser && <ElementButton style={styles.login} onClick={() => setLogin(true)}>Log in for use of chat</ElementButton>}*/}
        </>
    )
}