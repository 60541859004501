var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var index_exports = {};
__export(index_exports, {
  Request: () => Request,
  apis: () => apis,
  get: () => get,
  useAnalytics: () => useAnalytics,
  useAuthenticated: () => useAuthenticated,
  useAuthenticatedChannels: () => useAuthenticatedChannels,
  useBillings: () => useBillings,
  useCategories: () => useCategories,
  useChannel: () => useChannel,
  useChannels: () => useChannels,
  useChannelsHomepage: () => useChannelsHomepage,
  useChannelsUser: () => useChannelsUser,
  useComments: () => useComments,
  useCustomersCount: () => useCustomersCount,
  useEmails: () => useEmails,
  useEvent: () => useEvent,
  useEvents: () => useEvents,
  useEventsCount: () => useEventsCount,
  useLazyLoad: () => useLazyLoad,
  useLikeUser: () => useLikeUser,
  useLikesCount: () => useLikesCount,
  useLivestream: () => useLivestream,
  useLivestreams: () => useLivestreams,
  useNotifications: () => useNotifications,
  useOrder: () => useOrder,
  useOrders: () => useOrders,
  usePlan: () => usePlan,
  usePlans: () => usePlans,
  usePlaylist: () => usePlaylist,
  usePlaylists: () => usePlaylists,
  usePullzones: () => usePullzones,
  useSports: () => useSports,
  useSubscribersCount: () => useSubscribersCount,
  useSubscription: () => useSubscription,
  useSubscriptions: () => useSubscriptions,
  useTags: () => useTags,
  useTeams: () => useTeams,
  useTokens: () => useTokens,
  useUserExists: () => useUserExists,
  useVideo: () => useVideo,
  useVideos: () => useVideos,
  useVoucher: () => useVoucher,
  useVouchers: () => useVouchers
});
module.exports = __toCommonJS(index_exports);

// src/methods.ts
var import_axios = __toESM(require("axios"));
var import_react = require("react");
var headers = {
  "X-Requested-With": "XMLHttpRequest"
};
if (process.env.NEXT_PUBLIC_USER_TOKEN) {
  headers.Authorization = `Bearer ${process.env.NEXT_PUBLIC_USER_TOKEN}`;
}
var axios = import_axios.default.create({
  headers,
  withCredentials: true
});
var apis = {
  backend: process.env.NEXT_PUBLIC_BACKEND_URL,
  media: process.env.NEXT_PUBLIC_MEDIA_URL,
  auth: process.env.NEXT_PUBLIC_AUTH_URL,
  ecommerce: process.env.NEXT_PUBLIC_ECOMMERCE_URL
};
var Request = class _Request {
  api;
  headers;
  isPublic;
  isPrivate;
  setErrors;
  setSubmitting;
  constructor(api, settings) {
    this.api = api;
    if (settings == null ? void 0 : settings.public) {
      this.isPublic = true;
    }
    if (settings == null ? void 0 : settings.private) {
      this.isPrivate = true;
    }
    this.headers = {};
  }
  static fromApi(api, settings) {
    return new _Request(api, settings);
  }
  config() {
    return this.headers ? { headers: this.headers } : null;
  }
  withToken(token) {
    this.headers.Authorization = `Bearer ${token}`;
    return this;
  }
  withErrors(setErrors) {
    this.setErrors = setErrors;
    return this;
  }
  withSubmitting(setSubmitting) {
    this.setSubmitting = setSubmitting;
    return this;
  }
  startRequest() {
    if (this.setErrors) {
      this.setErrors([]);
    }
    if (this.setSubmitting) {
      this.setSubmitting(true);
    }
  }
  endRequest() {
    if (this.setSubmitting) {
      this.setSubmitting(false);
    }
  }
  url(url, queryParams) {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      Object.entries(queryParams).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((item) => searchParams.append(`${key}[]`, item));
        } else {
          searchParams.append(key, value);
        }
      });
    }
    const queryString = searchParams.toString();
    return `${apis[this.api]}${this.isPublic ? "/public" : ""}${this.isPrivate ? "/private" : ""}${url}${queryString ? `?${queryString}` : ""}`;
  }
  async get(url, queryParams) {
    this.startRequest();
    const response = await get(this.url(url, queryParams), this.config());
    this.endRequest();
    return response;
  }
  async post(url, body) {
    this.startRequest();
    try {
      const response = await axios.post(this.url(url), form(body));
      this.endRequest();
      return response.data;
    } catch (error) {
      return checkForErrors(error, this.setErrors);
    }
  }
  async put(url, body = null) {
    this.startRequest();
    try {
      const response = await axios.post(this.url(url), form({ ...body, _method: "PUT" }));
      this.endRequest();
      return response.data;
    } catch (error) {
      return checkForErrors(error, this.setErrors);
    }
  }
  async delete(url) {
    this.startRequest();
    try {
      let res = await axios.delete(this.url(url));
      this.endRequest();
      return await res.data;
    } catch (error) {
      return checkForErrors(error, this.setErrors);
    }
  }
};
function form(params) {
  let formData = new FormData();
  return createForm(formData, params);
}
function checkForErrors(response, setErrors) {
  var _a;
  if (!((_a = response == null ? void 0 : response.response) == null ? void 0 : _a.data)) {
    const messages = [{ message: "something went wrong" }];
    if (setErrors) {
      setErrors(messages);
    }
    return { errors: messages };
  }
  if (!setErrors) {
    return { errors: response.response.data };
  }
  if (response.response.data.message && !response.response.data.errors) {
    if (setErrors) {
      setErrors([{
        message: response.response.data.message
      }]);
    }
    return { errors: response.message };
  }
  const errors = response.response.data.errors;
  if (errors) {
    const messages = [];
    for (const key in errors) {
      messages.push({ message: errors[key][0] });
    }
    if (setErrors) {
      setErrors(messages);
    }
    return { errors: messages };
  }
  return { errors: response.response.data };
}
async function get(url, config) {
  try {
    let res = await axios.get(url, config);
    return await res.data;
  } catch (error) {
    return checkForErrors(error);
  }
}
var createForm = (formData, params, key = "") => {
  for (let i in params) {
    if (!params.hasOwnProperty(i)) {
      continue;
    }
    let formKey = key ? key + `[${i}]` : i;
    if (Array.isArray(params[i]) && !params[i].length) {
      formData.append(formKey, params[i]);
      continue;
    }
    if (params[i] !== null && (Array.isArray(params[i]) || typeof params[i] === "object") && !(params[i] instanceof File || params[i] instanceof Date)) {
      formData = createForm(formData, params[i], formKey);
      continue;
    }
    formData.append(formKey, params[i] === null ? "" : params[i]);
  }
  return formData;
};
function useLazyLoad(enabled, data, queryParams) {
  const [records, setRecords] = (0, import_react.useState)([]);
  (0, import_react.useEffect)(() => {
    if (!data) {
      return;
    }
    const newRecords = (data == null ? void 0 : data.data) || [];
    if ((queryParams == null ? void 0 : queryParams.page) === 1 || !enabled) {
      setRecords(newRecords);
      return;
    }
    setRecords([...records, ...newRecords]);
  }, [data == null ? void 0 : data.data]);
  return records;
}

// src/analytics.ts
var import_immutable = __toESM(require("swr/immutable"));
function useAnalytics(queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable.default)(
    Request.fromApi("backend", settings).url(`/analytics`, queryParams),
    get
  );
  const analytics = useLazyLoad(settings == null ? void 0 : settings.lazyloading, data, queryParams);
  return {
    data: analytics,
    meta: data == null ? void 0 : data.meta,
    error,
    isLoading,
    mutate
  };
}

// src/authenticated.ts
var import_immutable2 = __toESM(require("swr/immutable"));
function useAuthenticated() {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable2.default)(
    Request.fromApi("auth").url(`/api/user`),
    get
  );
  return {
    data: data == null ? void 0 : data.data,
    error,
    isLoading,
    mutate
  };
}
function useAuthenticatedChannels(id, queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable2.default)(
    Request.fromApi("backend", settings).url(`/user/${id}/channels`, queryParams),
    get
  );
  const channels = useLazyLoad(settings == null ? void 0 : settings.lazyloading, data, queryParams);
  return {
    data: channels,
    meta: data == null ? void 0 : data.meta,
    error,
    isLoading,
    mutate
  };
}

// src/billings.ts
var import_immutable3 = __toESM(require("swr/immutable"));
function useBillings(queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable3.default)(
    Request.fromApi("ecommerce", settings).url(`/billings`, queryParams),
    get
  );
  return {
    data: useLazyLoad(settings == null ? void 0 : settings.lazyloading, data, queryParams),
    meta: data == null ? void 0 : data.meta,
    error,
    isLoading,
    mutate
  };
}

// src/categories.ts
var import_immutable4 = __toESM(require("swr/immutable"));
function useCategories(queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable4.default)(
    Request.fromApi("backend", settings).url(`/categories`, queryParams),
    get
  );
  return {
    data: (data == null ? void 0 : data.data) || [],
    meta: data == null ? void 0 : data.meta,
    error,
    isLoading,
    mutate
  };
}

// src/channels.ts
var import_immutable5 = __toESM(require("swr/immutable"));
function useChannels(queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable5.default)(
    Request.fromApi("backend", settings).url(`/channels`, queryParams),
    get
  );
  const channels = useLazyLoad(settings == null ? void 0 : settings.lazyloading, data, queryParams);
  return {
    data: channels,
    meta: data == null ? void 0 : data.meta,
    error,
    isLoading,
    mutate
  };
}
function useChannel(id, queryParams) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable5.default)(
    Request.fromApi("backend").url(`/channels/${id}`, queryParams),
    get
  );
  return {
    data: data == null ? void 0 : data.data,
    error,
    isLoading,
    mutate
  };
}

// src/channelsHomepage.ts
var import_immutable6 = __toESM(require("swr/immutable"));
function useChannelsHomepage(queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable6.default)(
    Request.fromApi("backend", settings).url(`/channels/homepage`, queryParams),
    get
  );
  const channelsHomepage = useLazyLoad(settings == null ? void 0 : settings.lazyloading, data, queryParams);
  return {
    data: channelsHomepage,
    meta: data == null ? void 0 : data.meta,
    error,
    isLoading,
    mutate
  };
}

// src/channelsUser.ts
var import_immutable7 = __toESM(require("swr/immutable"));
function useChannelsUser(queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable7.default)(
    Request.fromApi("backend", settings).url(`/user_channels`, queryParams),
    get
  );
  return {
    data: (data == null ? void 0 : data.data) || [],
    meta: data == null ? void 0 : data.meta,
    error,
    isLoading,
    mutate
  };
}

// src/comments.ts
var import_immutable8 = __toESM(require("swr/immutable"));
function useComments(queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable8.default)(
    Request.fromApi("media", settings).url(`/comments`, queryParams),
    get
  );
  const comments = useLazyLoad(settings == null ? void 0 : settings.lazyloading, data, queryParams);
  return {
    data: comments,
    meta: data == null ? void 0 : data.meta,
    error,
    isLoading,
    mutate
  };
}

// src/customers.ts
var import_immutable9 = __toESM(require("swr/immutable"));
function useCustomersCount(queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable9.default)(
    Request.fromApi("ecommerce", settings).url(`/customers/count`, queryParams),
    get
  );
  return {
    data: (data == null ? void 0 : data.data) || { count: 0 },
    error,
    isLoading,
    mutate
  };
}

// src/emails.ts
var import_immutable10 = __toESM(require("swr/immutable"));
function useEmails(queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable10.default)(
    Request.fromApi("backend", settings).url("/emails", queryParams),
    get
  );
  return {
    data: useLazyLoad(settings == null ? void 0 : settings.lazyloading, data, queryParams),
    meta: data == null ? void 0 : data.meta,
    error,
    isLoading,
    mutate
  };
}

// src/events.ts
var import_immutable11 = __toESM(require("swr/immutable"));
var import_react2 = require("react");
function useEvents(queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable11.default)(
    Request.fromApi("media", settings).url(`/events`, queryParams),
    get
  );
  const events = useLazyLoad(settings == null ? void 0 : settings.lazyloading, data, queryParams);
  const [meta, setMeta] = (0, import_react2.useState)(null);
  (0, import_react2.useEffect)(() => {
    if (data == null ? void 0 : data.meta) {
      setMeta(data.meta);
    }
  }, [data == null ? void 0 : data.meta]);
  return {
    data: events,
    meta,
    error,
    isLoading,
    mutate
  };
}
function useEventsCount(queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable11.default)(
    Request.fromApi("media", settings).url(`/events/count`, queryParams),
    get
  );
  return {
    data: (data == null ? void 0 : data.data) || { count: 0 },
    error,
    isLoading,
    mutate
  };
}
function useEvent(id, queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable11.default)(
    Request.fromApi("media", settings).url(`/events/${id}`, queryParams),
    get
  );
  return {
    data: data == null ? void 0 : data.data,
    error,
    isLoading,
    mutate
  };
}

// src/likes.ts
var import_immutable12 = __toESM(require("swr/immutable"));
function useLikesCount(queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable12.default)(
    Request.fromApi("media", settings).url(`/likes/count`, queryParams),
    get
  );
  return {
    data: (data == null ? void 0 : data.data) || { count: 0 },
    meta: data == null ? void 0 : data.meta,
    error,
    isLoading,
    mutate
  };
}
function useLikeUser(queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable12.default)(
    Request.fromApi("media", settings).url(`/likes`, queryParams),
    get
  );
  return {
    data: data == null ? void 0 : data.data,
    error,
    isLoading,
    mutate
  };
}

// src/livestreams.ts
var import_immutable13 = __toESM(require("swr/immutable"));
function useLivestreams(queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable13.default)(
    Request.fromApi("media", settings).url(`/livestreams`, queryParams),
    get
  );
  const livestreams = useLazyLoad(settings == null ? void 0 : settings.lazyloading, data, queryParams);
  return {
    data: livestreams,
    meta: data == null ? void 0 : data.meta,
    error,
    isLoading,
    mutate
  };
}
function useLivestream(id, queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable13.default)(
    Request.fromApi("media", settings).url(`/livestreams/${id}`, queryParams),
    get
  );
  return {
    data: data == null ? void 0 : data.data,
    error,
    isLoading,
    mutate
  };
}

// src/notifications.ts
var import_immutable14 = __toESM(require("swr/immutable"));
function useNotifications(queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable14.default)(
    Request.fromApi("backend", settings).url(`/notifications`, queryParams),
    get
  );
  const notifications = useLazyLoad(settings == null ? void 0 : settings.lazyloading, data, queryParams);
  return {
    data: notifications,
    meta: data == null ? void 0 : data.meta,
    error,
    isLoading,
    mutate
  };
}

// src/orders.ts
var import_immutable15 = __toESM(require("swr/immutable"));
function useOrders(queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable15.default)(
    Request.fromApi("ecommerce", settings).url(`/orders`, queryParams),
    get
  );
  const orders = useLazyLoad(settings == null ? void 0 : settings.lazyloading, data, queryParams);
  return {
    data: orders,
    meta: data == null ? void 0 : data.meta,
    error,
    isLoading,
    mutate
  };
}
function useOrder(id) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable15.default)(
    Request.fromApi("ecommerce").url(`/orders/${id}`),
    get
  );
  return {
    data: data == null ? void 0 : data.data,
    error,
    isLoading,
    mutate
  };
}

// src/plans.ts
var import_immutable16 = __toESM(require("swr/immutable"));
function usePlans(queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable16.default)(
    Request.fromApi("ecommerce", settings).url(`/plans`, queryParams),
    get
  );
  const plans = useLazyLoad(settings == null ? void 0 : settings.lazyloading, data, queryParams);
  return {
    data: plans,
    meta: data == null ? void 0 : data.meta,
    error,
    isLoading,
    mutate
  };
}
function usePlan(id, queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable16.default)(
    Request.fromApi("ecommerce", settings).url(`/plans/${id}`, queryParams),
    get
  );
  return {
    data: data == null ? void 0 : data.data,
    error,
    isLoading,
    mutate
  };
}

// src/playlists.ts
var import_immutable17 = __toESM(require("swr/immutable"));
function usePlaylists(queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable17.default)(
    Request.fromApi("backend", settings).url(`/playlists`, queryParams),
    get
  );
  const playlists = useLazyLoad(settings == null ? void 0 : settings.lazyloading, data, queryParams);
  return {
    data: playlists,
    meta: data == null ? void 0 : data.meta,
    error,
    isLoading,
    mutate
  };
}
function usePlaylist(id, queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable17.default)(
    Request.fromApi("backend", settings).url(`/playlists/${id}`, queryParams),
    get
  );
  return {
    data: data == null ? void 0 : data.data,
    error,
    isLoading,
    mutate
  };
}

// src/pullzones.ts
var import_immutable18 = __toESM(require("swr/immutable"));
function usePullzones(queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable18.default)(
    Request.fromApi("media", settings).url(`/pullzones`, queryParams),
    get
  );
  const livestreams = useLazyLoad(settings == null ? void 0 : settings.lazyloading, data, queryParams);
  return {
    data: livestreams,
    meta: data == null ? void 0 : data.meta,
    error,
    isLoading,
    mutate
  };
}

// src/sports.ts
var import_immutable19 = __toESM(require("swr/immutable"));
function useSports(queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable19.default)(
    Request.fromApi("backend", settings).url(`/sports`, queryParams),
    get
  );
  return {
    data: (data == null ? void 0 : data.data) || [],
    meta: data == null ? void 0 : data.meta,
    error,
    isLoading,
    mutate
  };
}

// src/subscribers.ts
var import_immutable20 = __toESM(require("swr/immutable"));
function useSubscribersCount(queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable20.default)(
    Request.fromApi("media", settings).url(`/subscribers/count`, queryParams),
    get
  );
  return {
    data: (data == null ? void 0 : data.data) || { count: 0 },
    error,
    isLoading,
    mutate
  };
}

// src/subscriptions.ts
var import_immutable21 = __toESM(require("swr/immutable"));
function useSubscriptions(queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable21.default)(
    Request.fromApi("ecommerce", settings).url(`/subscriptions`, queryParams),
    get
  );
  const subscriptions = useLazyLoad(settings == null ? void 0 : settings.lazyloading, data, queryParams);
  return {
    data: subscriptions,
    meta: data == null ? void 0 : data.meta,
    error,
    isLoading,
    mutate
  };
}
function useSubscription(id, queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable21.default)(
    Request.fromApi("ecommerce", settings).url(`/subscriptions/${id}`, queryParams),
    get
  );
  return {
    data: data == null ? void 0 : data.data,
    error,
    isLoading,
    mutate
  };
}

// src/tags.ts
var import_immutable22 = __toESM(require("swr/immutable"));
function useTags(queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable22.default)(
    Request.fromApi("backend", settings).url(`/tags`, queryParams),
    get
  );
  return {
    data: (data == null ? void 0 : data.data) || [],
    meta: data == null ? void 0 : data.meta,
    error,
    isLoading,
    mutate
  };
}

// src/teams.ts
var import_immutable23 = __toESM(require("swr/immutable"));
function useTeams(queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable23.default)(
    Request.fromApi("backend", settings).url(`/teams`, queryParams),
    get
  );
  const teams = useLazyLoad(settings == null ? void 0 : settings.lazyloading, data, queryParams);
  return {
    data: teams,
    meta: data == null ? void 0 : data.meta,
    error,
    isLoading,
    mutate
  };
}

// src/tokens.ts
var import_immutable24 = __toESM(require("swr/immutable"));
function useTokens(queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable24.default)(
    Request.fromApi("auth", settings).url(`/api/user/tokens`, queryParams),
    get
  );
  const tokens = useLazyLoad(settings == null ? void 0 : settings.lazyloading, data, queryParams);
  return {
    data: tokens,
    meta: data == null ? void 0 : data.meta,
    error,
    isLoading,
    mutate
  };
}

// src/users.ts
var import_immutable25 = __toESM(require("swr/immutable"));
function useUserExists(email) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable25.default)(
    Request.fromApi("auth").url(`/api/users/${email}/exists`),
    get
  );
  return {
    data: data == null ? void 0 : data.data,
    error,
    isLoading,
    mutate
  };
}

// src/videos.ts
var import_immutable26 = __toESM(require("swr/immutable"));
function useVideos(queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable26.default)(
    Request.fromApi("backend", settings).url(`/videos${(settings == null ? void 0 : settings.trending) ? "/trending" : ""}`, queryParams),
    get
  );
  const videos = useLazyLoad(settings == null ? void 0 : settings.lazyloading, data, queryParams);
  return {
    data: videos,
    meta: data == null ? void 0 : data.meta,
    error,
    isLoading,
    mutate
  };
}
function useVideo(id, queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable26.default)(
    Request.fromApi("backend", settings).url(`/videos/${id}`, queryParams),
    get
  );
  return {
    data: data == null ? void 0 : data.data,
    error,
    isLoading,
    mutate
  };
}

// src/vouchers.ts
var import_immutable27 = __toESM(require("swr/immutable"));
function useVouchers(queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable27.default)(
    Request.fromApi("ecommerce", settings).url(`/vouchers`, queryParams),
    get
  );
  const vouchers = useLazyLoad(settings == null ? void 0 : settings.lazyloading, data, queryParams);
  return {
    data: vouchers,
    meta: data == null ? void 0 : data.meta,
    error,
    isLoading,
    mutate
  };
}
function useVoucher(id, queryParams, settings) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable27.default)(
    Request.fromApi("ecommerce", settings).url(`/vouchers/${id}`, queryParams),
    get
  );
  return {
    data: data == null ? void 0 : data.data,
    error,
    isLoading,
    mutate
  };
}
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  Request,
  apis,
  get,
  useAnalytics,
  useAuthenticated,
  useAuthenticatedChannels,
  useBillings,
  useCategories,
  useChannel,
  useChannels,
  useChannelsHomepage,
  useChannelsUser,
  useComments,
  useCustomersCount,
  useEmails,
  useEvent,
  useEvents,
  useEventsCount,
  useLazyLoad,
  useLikeUser,
  useLikesCount,
  useLivestream,
  useLivestreams,
  useNotifications,
  useOrder,
  useOrders,
  usePlan,
  usePlans,
  usePlaylist,
  usePlaylists,
  usePullzones,
  useSports,
  useSubscribersCount,
  useSubscription,
  useSubscriptions,
  useTags,
  useTeams,
  useTokens,
  useUserExists,
  useVideo,
  useVideos,
  useVoucher,
  useVouchers
});
