import React, {useState} from 'react'
import {Components, Hooks, Functions} from 'eyecons-components'
import {useRouter} from "next/router";
import {PasswordForm, PopularPasswords, SetPage, usePasswordInfo} from "src/components";
import {Request} from 'eyecons-requests'

function initUser(email?: string, commercial?: boolean, isNoLogin?: boolean) {
    const user: any = {
        email: isNoLogin ? email : '',
        options: {
            terms: isNoLogin,
            commercial: Boolean((commercial && isNoLogin)),
        },
    }

    if (!isNoLogin) {
        user.password = ''
        user.password_confirmation = ''
    }

    return user
}

export type RegisterFormProps = {
    title?: string
    setPage?: SetPage
    locale?: string
    onRegistered?: () => void
}

export function RegisterForm ({title = 'Register', setPage, locale: initialLocale, onRegistered}: RegisterFormProps) {
    const router = useRouter()
    const isNoLogin = router.query.type === 'nologin'
    const [errors, setErrors] = useState<Array<{message: string}>>([])
    const [user, setUser] = useState<any>(initUser(router.query.email as string, Boolean(router.query.commercial), isNoLogin))
    const [Locale, locale] = Hooks.useLocale(initialLocale || router.query.locale)
    const [passwordInfo, setPasswordInfo, validate] = usePasswordInfo(user, locale)

    function submit() {
        if (!user.options.terms) {
            return setErrors([
                {
                    message: Functions.translate(isNoLogin ? 'To watch live you have to agree with the terms and conditions' : 'To register you have to agree with the terms and conditions', locale),
                },
            ])
        }

        if (!isNoLogin && !validate()) {
            return
        }

        Request.fromApi('auth').withErrors(setErrors).post(isNoLogin ? '/api/users/nologin' : '/register', {...user, locale}).then((response: any) => {
            if (response.data) {
                if(onRegistered) {
                    onRegistered()
                    return
                }

                if (setPage) {
                    setPage({
                        page: 'profile',
                        locale
                    })

                    return
                }

                router.push(`/register/profile?locale=${locale}`)
            }
        })
    }

    return <>
        <div className={'relative text-center mb-4'}>
            {!isNoLogin && <Locale/>}
            {title && <Components.Texts.Heading type={'h5'} appendClassname={'text-dark'}>{Functions.translate(title, locale)}</Components.Texts.Heading>}
        </div>

        {
            isNoLogin && (<Components.Forms.Field className={'max-w-[420px] mx-auto text-gray-400'}>
            <Components.Texts.Small>Door de <a href="/terms-and-conditions" target="_blank">algemene voorwaarden</a> van Eyecons te accepteren word je met {user.email} geregistreerd bij Eyecons en kun je deze video bekijken.<br/></Components.Texts.Small>
            </Components.Forms.Field>)
        }
        {
            !isNoLogin && <>
                <Components.Texts.Primary appendClassname={'mb-2 text-center'}>
                    {Functions.translate('Already registered?', locale)}
                    {' '}
                    {
                        setPage && <button className={'text-blue-200'} onClick={() => setPage({
                            page: 'login',
                            locale
                        })}>{Functions.translate('Login')}</button>
                    }
                    {
                        !setPage && <Components.Texts.Link href={'/login'}>{Functions.translate('Login')}</Components.Texts.Link>
                    }
                </Components.Texts.Primary>

                <PasswordForm
                    locale={locale}
                    user={user}
                    setUser={setUser}
                    passwordInfo={passwordInfo}
                    setPasswordInfo={setPasswordInfo}
                />

                <Components.Forms.Checkbox
                    className={'mb-2'}
                    id={'terms'}
                    value={user.options.terms}
                    onChange={(terms: boolean) => setUser({...user, options: {...user.options, terms}})}
                >
                    {Functions.translate('I agree with the', locale) + ' '}
                    <Components.Texts.Link target={'_blank'} href={'/terms-and-conditions'}>
                        {Functions.translate('Terms and Conditions', locale)}
                    </Components.Texts.Link>
                    {' ' + Functions.translate('from Eyecons', locale)}
                </Components.Forms.Checkbox>
            </>
        }

        {
            (!router.query.commercial || !isNoLogin) && <Components.Forms.Checkbox
                id={'commercial'}
                value={user.options.commercial}
                onChange={(commercial: boolean) => setUser({...user, options: {...user.options, commercial}})}
            >
                {Functions.translate('I give Eyecons and its partners permission to send me e-mail messages with offers, promotions and other promotional expressions. You can undo this at any time, read our', locale) + ' '}
                <Components.Texts.Link target={'_blank'} href={'/privacy'}>
                    {Functions.translate('Privacy Policy', locale)}
                </Components.Texts.Link>
                {' ' + Functions.translate('for more information.', locale)}
            </Components.Forms.Checkbox>
        }

        <Components.Forms.Buttons
            icon={'chevron-right'}
            icon_position={'right'}
            type={'wide'}
            className={'mb-4'}
            errors={errors}
            title={`${Functions.translate(isNoLogin ? 'Accept' : 'Register', locale)}${isNoLogin ? '' : ' (1/2)'}`}
            submit={submit}
        />
    </>
}