import React, {useContext} from 'react'
import {useEffect, useState} from 'react'
import {Components, Hooks} from 'eyecons-components'
import {AppContext} from "src/layouts";
import * as Requests from 'eyecons-requests'
import {AgeVerification, Birthday} from 'src/components'

function accessToLocalStorage() {
    try {
        return typeof localStorage !== 'undefined'
    } catch (e) {
        return false
    }
}

type BettingProps = {
    embed?: boolean,
    forceAuth?: boolean,
    showAfterSeconds?: number
}

export function Betting({embed = false, forceAuth = false, showAfterSeconds = 0}: BettingProps) {
    const {authenticated} = useContext(AppContext)
    const [hidden, setHidden] = useState(true)
    const [user, setUser, submitUser] = Hooks.useUser(authenticated, embed)

    function show() {
        if (showAfterSeconds > 0) {
            setTimeout(() => {
                setHidden(false)
            }, showAfterSeconds * 1000)

            return
        }

        setHidden(false)
    }

    useEffect(() => {
        // In embed no user and local storage is not available so we cannot show the modal
        if (!authenticated && !accessToLocalStorage()) {
            return
        }

        const options = user.options || {}

        // If age_verification is already answered do not show the modal
        if (!options.hasOwnProperty('advertising_disabled')) {
            return show();
        }
    }, [])

    function submit(data = {}) {
        submitUser(data).then((response: any) => {
            if (response) {
                setHidden(true)
            }
        })
    }

    function onCancel() {
        const options = user.options
        options.age_verification = false

        if (authenticated) {
            Requests.Request.fromApi('auth').put('/user', {options}).then(response => {
                if (response) {
                    setHidden(true)
                }
            })
        } else {
            submitUser({options}).then((response: any) => {
                if (response) {
                    setHidden(true)
                }
            })
        }
    }

    if (forceAuth && !authenticated || hidden) {
        return <></>
    }

    function screen() {
        if (!hidden && embed) {
            return <AgeVerification
                user={user}
                submitUser={(user: any) => submit(user)}
            />
        }

        return <Birthday
            user={user}
            submitUser={(user: any) => submit(user)}
        />
    }

    return <Components.Layouts.Modal
        type={'popup'}
        small
        embed={embed}
        translucent={embed}
        onClick={onCancel}
    >
        {screen()}
    </Components.Layouts.Modal>
}