import React, {useContext} from "react";
import {Components} from "eyecons-components";
import {VideoChannel, VideoMatch} from "src/components";
import {usePlans} from "eyecons-requests";
import {AppContext, VideoContext} from "src/layouts";

type VideoContentProps = {
    paid: boolean,
}

function totalViewsText(totalViews: number) {
    return `${totalViews ?? 0} ${totalViews === 1 ? 'weergave' : 'weergaven'}`
}

export function VideoContent({paid}: VideoContentProps) {
    const {authenticated} = useContext(AppContext)
    const {video} = useContext(VideoContext)
    const searchKey = video.type == 'live' ? 'event_id' : 'video_id'
    const plans = usePlans({[searchKey]: video.id, hidden: false})

    return (
        <div className={'flex flex-col gap-8'}>
            {
                video.has_public_plans && !paid && authenticated && video.status === 'published' &&
                <div className={'md:-mt-12 lg:-mt-20 px-4 z-30'}>
                    <Components.Subscriptions.Cards plans={plans.data}/>
                </div>
            }

            {video.team && video.opponent && <VideoMatch video={video}/>}

            <Components.Texts.Heading type={'h3'}>{video.title}</Components.Texts.Heading>

            <div className={'flex justify-between'}>
                {Boolean(video.external_id) && video.status === 'published' && (
                    <Components.Likes.Create authenticated={authenticated} video={video}/>
                )}

                {video.type !== 'live' && (
                    <Components.Texts.Primary className={'text-gray-700'}>
                        {totalViewsText(video?.views || 0)} • {video.date.label}
                    </Components.Texts.Primary>
                )}
            </div>

            <div dangerouslySetInnerHTML={{__html: video.description}}/>

            <Components.Layouts.HR/>

            <VideoChannel
                icon={video.channel.logo}
                label={video.channel.label}
                name={video.channel.name}
                videos_count={video.channel.videos_count}
            />

            {Boolean(video.comments) && video.status === 'published' && (
                <>
                    <Components.Layouts.HR/>
                    <Components.Comments.Overview authenticated={authenticated} video={video}/>
                </>
            )}

            <Components.Layouts.HR className={'lg:hidden'}/>
        </div>
    )
}