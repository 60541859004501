import React, {useEffect, useState} from 'react'
import {Components} from 'eyecons-components'
import {Request} from 'eyecons-requests'
import {useRouter} from "next/router";

type TwoFactorFormProps = {
    id: number
    signature: string
    expires: string
}

export function TwoFactorForm({id, signature, expires}: TwoFactorFormProps) {
    const router = useRouter()
    const [code, setCode] = useState<string>('')
    const [errors, setErrors] = useState([])
    const [email, setEmail] = useState(null)
    const [loading, setLoading] = useState(true)

    function getTwoFactorUrl() {
        return `/login/${id}/2fa?expires=${expires}&signature=${signature}`
    }

    function submit() {
        Request.fromApi('auth').withErrors(setErrors).post(getTwoFactorUrl(), {code}).then((response): any => {
            if (response.redirect) {
                return router.push(response.redirect)
            }
        })
    }

    useEffect(() => {
        Request.fromApi('auth')
            .withErrors(setErrors)
            .post(getTwoFactorUrl(), {validate: true})
            .then((response): any => {
                setLoading(false)
                if (response.data) {
                    setEmail(response.data.email)
                }
            }, setErrors)
    }, []);


    if (loading) {
        return null;
    }

    return <>
        <div className={'relative text-center mb-4'}>
            <Components.Texts.Heading type={'h5'} appendClassname={'text-dark mb-4'}>
                Verificatie in 2 stappen
            </Components.Texts.Heading>

            <Components.Texts.Primary>We hebben net een 6-cijferige code gestuurd naar {email}. Vul deze hieronder
                in.</Components.Texts.Primary>
        </div>

        <Components.Forms.Field>
            <Components.Forms.Input
                placeholder={'Code'}
                type={'text'}
                onChange={(code: string) => setCode(code)}
                value={code}
            />
        </Components.Forms.Field>

        <Components.Forms.Buttons
            type={'wide'}
            errors={errors}
            title={'Login'}
            submit={submit}
        />
    </>
}
