import Link from 'next/link'

export function Footer() {
    return (
        <footer className="py-8 md:py-16 px-4 bg-[#efeff4] text-dark">
            <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
                <ul className="flex flex-col space-y-3">
                    <li>
                        <Link href="/">Homepage</Link>
                    </li>
                    <li>
                        <Link href="/videos/trending">Trending</Link>
                    </li>
                    <li>
                        <Link href="/videos/nieuw">Nieuw</Link>
                    </li>
                    <li>
                        <Link href="/kanalen">Clubs</Link>
                    </li>
                    <li>
                        <Link href={'/live'}>Live</Link>
                    </li>
                </ul>
                <ul className="flex flex-col space-y-3">
                    <li>
                        <Link href="/about">Over Eyecons</Link>
                    </li>
                    <li>
                        <Link href="https://apps.apple.com/nl/app/eyecons/id6451410028" target="_blank">Eyecons App - iOS</Link>
                    </li>
                    <li>
                        <Link href="https://play.google.com/store/apps/details?id=com.eyecons" target="_blank">Eyecons App - Android</Link>
                    </li>
                    <li>
                        <a href="https://careers.eyecons.com/" target="_blank">
                            Vacatures
                        </a>
                    </li>
                    <li>
                        <a href="https://eyecons.zendesk.com/" target="_blank">
                            Support
                        </a>
                    </li>
                    <li>
                        <a href="https://cdn.eyecons.com/docs/Casten.pdf" target="_blank">
                            Casten
                        </a>
                    </li>
                </ul>
                <ul className="flex flex-col space-y-3">
                    <li>
                        <Link href="/terms-and-conditions">Algemene voorwaarden</Link>
                    </li>
                    <li>
                        <Link href="/terms-and-conditions-partners">Algemene voorwaarden partners</Link>
                    </li>
                    <li>
                        <Link href="/privacy">Privacy Policy</Link>
                    </li>
                    <li>
                        <Link href="/?cmpscreen">Cookie instellingen</Link>
                    </li>
                </ul>
            </div>
        </footer>
    )
}
