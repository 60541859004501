import { createContext } from 'react'
import * as Requests from 'eyecons-requests'

type VideoContextType = {
    video: Requests.Video,
    setVideo: (video: Requests.Video) => void
}

export const VideoContext = createContext<VideoContextType>({
    video: {
    }
} as VideoContextType)
