import {useContext, useEffect, useState} from 'react'
import {Components} from 'eyecons-components'
import {isAndroid} from "react-device-detect"
import Link from 'next/link'
import {AppContext} from 'src/layouts'
import {useRouter} from "next/router";
import {AppBanner} from '../AppBanner'
import classNames from 'classnames'

export function Nav() {
    const router = useRouter()
    const {liveCount, authenticated, authenticatedIsLoading, searching, setSearching} = useContext(AppContext)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [showAppBanner, setAppBanner] = useState<boolean>(false)

    useEffect(() => {
        if (isOpen) {
            setIsOpen(false)
        }
    }, [router.pathname]);

    useEffect(() => setAppBanner(isAndroid), [isAndroid]);

    return (
    <nav className={'fixed top-0 left-0 z-50 w-full'}>
        {showAppBanner && <AppBanner/>}
        <div className={'bg-purple-500 flex items-center justify-between p-4 h-navbar'}>
            <div className={'flex gap-4 md:gap-8 items-center'}>
                <Components.Menus.Hamburger onClick={() => setIsOpen(!isOpen)} open={isOpen}/>

                <Link href={'/'}>
                    <img src={'/static/img/eyecons-logo.svg'} alt="Eyecons" className={'h-[20px]'}/>
                </Link>

                <ul className={'list-unstyled gap-8 hidden md:flex'}>
                    <li>
                        <Link href={'/kanalen'} className={'text-white'}>
                            CLUBS
                        </Link>
                    </li>
                    <li>
                        <Link href={'/videos/nieuw'} className={'text-white'}>
                            NIEUW
                        </Link>
                    </li>
                    <li className={'flex items-center space-x-1'}>
                        <Link href={'/live'} className={'text-white'}>
                            <span>LIVE</span>
                        </Link>

                        {liveCount > 0 && (
                            <div
                                className={'font-display text-xs font-semibold text-center whitespace-nowrap rounded-full w-4.5 h-4.5 text-white bg-red-500 flex item-center justify-center'}>
                                <span>{liveCount}</span>
                            </div>
                        )}
                    </li>
                    {/*<li>*/}
                    {/*    <Link href={'/clubtv'} className={'text-white'}>*/}
                    {/*        CLUB TV*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                </ul>
            </div>

            {!authenticatedIsLoading && (
                <div className={'flex gap-4 items-center'}>
                    <button onClick={() => setSearching(!searching)}>
                        <Components.Icons.Icon name={'search'} className={'w-8 h-8 text-white'}/>
                    </button>
                    <Components.Users.Notifications authenticated={authenticated}/>
                    <Components.Menus.Profile authenticated={authenticated}/>
                </div>
            )}

            {isOpen && (
                <div className={classNames(
                    'fixed right-0 left-0 bottom-0 bg-dark text-white p-4 z-50',
                    isAndroid ? 'top-navbar-app' : 'top-navbar'
                )}>
                    <div className="p-8 flex flex-col md:flex-row">
                        <div className="w-full max-w-xs flex flex-col space-y-3 mb-4 md:mb-0">
                            <h3 className="text-secondary-500 font-bold font-display text-2xl">Menu</h3>
                            <ul className="flex-1 flex flex-col space-y-3">
                                <li>
                                    <Link href={'/'}>Homepage</Link>
                                </li>
                                <li>
                                    <Link href={'/videos/trending'}>Trending</Link>
                                </li>
                                <li>
                                    <Link href={'/videos/nieuw'}>Nieuw</Link>
                                </li>
                                <li>
                                    <Link href={'/kanalen'}>Clubs</Link>
                                </li>
                                <li>
                                    <Link href={'/live'}>Live</Link>
                                </li>
                            </ul>
                        </div>

                        <div className="w-full max-w-xs flex flex-col space-y-3">
                            <h3 className="text-secondary-500 font-bold font-display text-2xl">Eyecons</h3>
                            <ul className="flex-1 flex flex-col space-y-3">
                                <li>
                                    <Link href={'/about'}>Over Eyecons</Link>
                                </li>
                                <li>
                                    <Link href="https://apps.apple.com/nl/app/eyecons/id6451410028" target="_blank">Eyecons App - iOS</Link>
                                </li>
                                <li>
                                    <Link href="https://play.google.com/store/apps/details?id=com.eyecons" target="_blank">Eyecons App - Android</Link>
                                </li>
                                <li>
                                    <a href={'https://careers.eyecons.com/'} target="_blank">
                                        Vacatures
                                    </a>
                                </li>
                                <li>
                                    <a href={'https://eyecons.zendesk.com/'} target="_blank">
                                        Support
                                    </a>
                                </li>
                                <li>
                                    <a href={'https://cdn.eyecons.com/docs/Casten.pdf'} target="_blank">
                                        Casten
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="w-full max-w-xs flex flex-col space-y-3">
                            <ul className="flex-1 flex flex-col space-y-3">
                                <li>
                                    <Link href={'/terms-and-conditions'}>Algemene voorwaarden</Link>
                                </li>
                                <li>
                                    <Link href={'/terms-and-conditions-partners'}>Algemene voorwaarden partners</Link>
                                </li>
                                <li>
                                    <Link href={'/privacy'}>Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link href={'/?cmpscreen'}>Cookie instellingen</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </div>
        </nav>
    )
}
