import React, {useContext} from 'react'
import {AppContext, VideoContext} from "src/layouts";
import {PlayerLoginWindow, PlayerVod} from "src/components";
import {Components, Hooks} from "eyecons-components";
import {useRouter} from "next/router";

type PlayerLiveProps = {
    autoplay?: boolean,
    playable?: boolean,
    isAuthenticated?: boolean
    hideLogin?: boolean
}

export function PlayerLive({playable, autoplay = false, isAuthenticated, hideLogin = false}: PlayerLiveProps) {
    const router = useRouter()
    const {authenticated} = useContext(AppContext)
    const {video, setVideo} = useContext(VideoContext)
    const pollVideo = Hooks.usePolling(authenticated, video, setVideo)

    return <>
        {
            authenticated && authenticated.too_many_sessions && (
                <div className={'absolute z-10 inset-0 bg-gray-900/60 aspect-video backdrop-blur-sm flex flex-col justify-center items-center gap-10'}>
                    <Components.Texts.Primary className={'font-bold text-white block'}>
                        Je kan op maximaal 3 devices tegelijk kijken, log uit op een van de je andere
                        devices.
                    </Components.Texts.Primary>
                    <Components.Buttons.Button onClick={() => router.reload()}>
                        opnieuw
                    </Components.Buttons.Button>
                </div>
            )
        }

        {
            !isAuthenticated && !hideLogin && (
                <PlayerLoginWindow/>
            )
        }

        {
            !playable && isAuthenticated && (
                <div
                    className={'absolute inset-0 bg-gray-900/60 backdrop-blur-sm flex flex-col justify-center items-center gap-10'}>
                    <Components.Texts.Primary className={'font-bold text-white block px-4 text-center'}>
                        Livestream is nog niet gestart of (tijdelijk) beëindigd. Even geduld a.u.b.
                    </Components.Texts.Primary>
                </div>
            )
        }

        <PlayerVod video={pollVideo} autoplay={autoplay} playable={playable}/>
    </>
}