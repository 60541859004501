import {Components} from 'eyecons-components'
import Link from 'next/link'

const PLAY_STORE_LINK = 'https://play.google.com/store/apps/details?id=com.eyecons'

export function AppBanner() {
    return (
        <div className={'flex bg-white items-center justify-between p-4 h-navbar-banner'}>
            <div className={'flex items-center justify-between gap-4'}>
                <Link href={PLAY_STORE_LINK}>
                    <img src={`${process.env.NEXT_PUBLIC_CDN_URL}/front-end/img/eyecons-logo-app.png`} alt="Eyecons App" className={'h-[60px] rounded-xl'} />
                </Link>
                <div className={'text-sm'}>
                    <div>Eyecons App</div>
                    <div className={'text-gray-400'}>voor de sport, voor de fans</div>
                </div>
            </div>
            <Components.Buttons.Link type={'blue'} to={PLAY_STORE_LINK}>
                Open
            </Components.Buttons.Link>
        </div>
    )
}
