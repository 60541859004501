import Requests from "eyecons-requests";
import {Components} from "eyecons-components";
import React from "react";
import {VideoChannel} from "src/components";

export function VideoMatch({video}: { video: Requests.Video }) {
    const is_home = video.match_location !== 'away'
    const home_score = is_home ? video.team_score : video.opponent_score
    const away_score = is_home ? video.opponent_score : video.team_score

    function renderChannel(team: Requests.Team, right?: boolean) {
        return (
            <VideoChannel
                icon={team.club.icon}
                label={team.club.label}
                name={team.club.name}
                right={right}
                hideLabelOnMobile
            />
        )
    }

    if (!video.team || !video.opponent) {
        return null
    }

    return (
        <div className={'items-center flex justify-between relative'}>
            {renderChannel(is_home ? video.team : video.opponent)}

            <div className={'absolute inset-0 items-center justify-center flex z-[-1]'}>
                <Components.Texts.Heading type={'h5'}>
                    {home_score === null ? '' : home_score}-{away_score === null ? '' : away_score}
                </Components.Texts.Heading>
            </div>

            {renderChannel(is_home ? video.opponent : video.team, true)}
        </div>
    )
}