import React from 'react'
import {Components} from 'eyecons-components'
import {Container} from "src/components";
import * as Requests from 'eyecons-requests';

type ChannelEventsProps = {
    channel: Requests.Channel
}

export function ChannelEvents({channel}: ChannelEventsProps) {
    const events = Requests.useEvents({
        channel_id: channel.id,
        published: true,
        paginate: 4,
        with_live_videos: true,
        teams: channel.teams,
    }, {public: true})

    if (!events.data.length) {
        return null
    }

    return <Container>
        <div className={'gap-2 sm:grid sm:gap-4 sm:grid-cols-3'}>
            {
                events.data.map((event, index) => {
                    return <Components.Videos.CardLive key={index} video={event}/>
                })
            }
        </div>
    </Container>
}

