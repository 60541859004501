import React, {useEffect, useState} from 'react'
import { VideoContext } from 'src/layouts'
import * as Requests from "eyecons-requests";

type VideoProps = {
    children: React.ReactNode
    video: Requests.Video
}

export function Video({ video: serverVideo, children }: VideoProps) {
    const [video, setVideo] = useState(serverVideo)

    useEffect(() => {
        if (serverVideo.id !== video.id) {
            setVideo(serverVideo)
        }
    }, [serverVideo]);

    return (
        <VideoContext.Provider
            value={{video, setVideo}}
        >
            {children}
        </VideoContext.Provider>
    )
}
