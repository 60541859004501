import {PlayerLive, PlayerNoLogin, PlayerPayment, PlayerVod, PlayerVoucher} from "src/components";
import React, {useContext, useEffect, useState} from "react";
import {AppContext, VideoContext} from "src/layouts";
import classNames from "classnames";
import {useRouter} from "next/router";
import {isAndroid} from "react-device-detect";

type VideoPlayerProps = {
    paid: boolean,
    setPaid: (paid: boolean) => void,
    hasPayment: boolean,
    isLive?: boolean,
    variant?: 'responsive' | 'fixed' | 'embed'
}

export function VideoPlayer({isLive, variant = 'responsive', paid, setPaid, hasPayment}: VideoPlayerProps) {
    const router = useRouter()
    const {authenticated, authenticatedIsLoading} = useContext(AppContext)
    const {video} = useContext(VideoContext)

    const [showAppBanner, setAppBanner] = useState<boolean>(false)

    useEffect(() => setAppBanner(isAndroid), [isAndroid]);

    function renderPlayer() {
        const isPlayable = video.status === 'published'

        if (router.query.type === 'nologin') {
            return (
                <PlayerNoLogin
                    autoplay={true}
                    playable={isPlayable}
                    is_live={isLive}
                />
            )
        }
        if (hasPayment && router.query.voucher) {
            return  (
                <PlayerVoucher
                    autoplay={true}
                    paid={paid}
                    setPaid={setPaid}
                />
            )
        }

        if (hasPayment) {
            return (
                <PlayerPayment
                    autoplay={true}
                    paid={paid}
                    setPaid={setPaid}
                    isLive={isLive}
                />
            )
        }

        if (isLive) {
            return (
                <PlayerLive
                    autoplay={true}
                    playable={Boolean(isPlayable && authenticated?.id)}
                    isAuthenticated={Boolean(authenticated?.id)}
                />
            )
        }

        return (
            <PlayerVod video={video} autoplay={true} playable={isPlayable}/>
        )
    }

    if (authenticatedIsLoading) {
        return null
    }

    return (
        <div className={classNames('z-40 mx-auto', {
              'w-full h-full': variant === 'embed',
              'md:static max-w-[1000px] fixed left-0 right-0': variant === 'responsive',
              [showAppBanner ? 'top-navbar-app' : 'top-navbar']: variant === 'responsive',
            }
          )}>
            <div className={classNames('relative', {
                'w-full h-full': variant === 'embed'
            })}>
                {renderPlayer()}
            </div>
        </div>
    )
}