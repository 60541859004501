import {Components} from "eyecons-components";
import {Container, ChannelFollow, ChannelLogos} from 'src/components'
import * as Requests from 'eyecons-requests'
import classNames from "classnames";
import {useEffect, useState} from "react";

type ChannelHeroProps = {
    channel: Requests.Channel
}

export function ChannelHero({channel}: ChannelHeroProps) {
    const plans = Requests.usePlans({channel_id: channel.id, hidden: false})

    function initQueryParams() {
        const params: any = {channel_id: channel.id}

        if (channel.teams) {
            params.teams = channel.teams
        }

        return params
    }

    const [queryParams, setQueryParams] = useState(initQueryParams)

    useEffect(() => {
        if (channel.id !== queryParams.channel_id) {
            setQueryParams(initQueryParams())
        }
    }, [channel.id]);

    return (
        <>
            <div
                className={classNames('relative z-40 bg-cover bg-center bg-no-repeat flex flex-col justify-evenly bg-dark gap-12 py-12', {
                    'sm:py-28 lg:py-32': channel.type.name === 'club',
                })}
                style={{backgroundImage: `url(${channel.banner})`}}
            >
                <div className={'absolute inset-0 z-10 bg-gradient-to-l from-[#0e0b3b00] to-[#0e0b3b] bg-no-repeat'}/>
                <Container
                    className={'gap-4 sm:gap-8 z-20 flex flex-col sm:flex-row sm:items-center'}
                    withVerticalPadding={false}
                >
                    <div className={'flex gap-4 sm:gap-8 items-center'}>
                        <img
                            className={'w-20 h-20 object-cover sm:h-32 sm:w-32 rounded-full'}
                            src={channel.logo}
                            alt={channel.label}
                        />
                        <div>
                            <Components.Texts.Heading className={'text-white !leading-none'}
                                                      type={'h3'}>{channel.label}</Components.Texts.Heading>
                            <Components.Texts.Primary
                                className={'text-white'}>{channel.videos_count}{" "}{channel.videos_count === 1 ? "video" : "video's"}</Components.Texts.Primary>
                        </div>
                    </div>

                    <div>
                        <ChannelFollow channel={channel}/>
                    </div>
                </Container>

                {channel.teams_count > 0 && channel.type.name === "competition" && (
                    <Container className="z-20" withVerticalPadding={false}>
                        <ChannelLogos channel={channel}/>
                    </Container>
                )}
            </div>

            {
                Boolean(plans.data.length) &&
                <Container className={classNames('px-4', {
                    '!pb-0': channel.type.name === 'competition'
                })}>
                    <Components.Subscriptions.Cards plans={plans.data}/>
                </Container>
            }


            {channel.type.name === "club" && (
                <div>
                    <Components.Events.Calender
                        queryParams={queryParams}
                        setQueryParams={setQueryParams}
                    />
                </div>
            )}
        </>
    );
}
