import React from 'react'
import {Components, Hooks} from 'eyecons-components'
import classNames from "classnames";
import {Channel} from "eyecons-requests";
import Link from "next/link";

type ChannelLogosSliderProps = {
    channels: Array<Channel>
    contained?: boolean
}

export function ChannelLogosSlider({ channels, contained = false}: ChannelLogosSliderProps) {
    const [Scroll] = Hooks.useScroll(`channels-small`)

    return (
        <Scroll variant="big" contentClassName="gap-3 container mx-auto px-4">
            {channels.map((channel, index) => {
                return (
                    <Link
                        href={`/kanalen/${channel.name}`}
                        key={index}
                        className={'text-center relative'}
                    >
                        <div
                            className={
                                channel.is_live
                                    ? 'rounded-full from-blue-500 to-secondary-500 bg-gradient-to-b relative z-10'
                                    : ''
                            }
                            style={{ padding: '5px' }}
                        >
                            <img
                                alt={channel.label}
                                src={channel.logo}
                                className={
                                    'h-24 w-24 rounded-full max-w-none object-cover object-center'
                                }
                                style={{ border: '5px solid #F2F2FA' }}
                            />
                        </div>
                        <div
                            className={
                                'uppercase text-2xs text-purple-900 font-bold mt-2 lg:mt-3 relative-z-10'
                            }
                        >
                            {channel.label}
                        </div>
                    </Link>
                )
            })}

            {
                channels.length > 20 && <a
                    href={'/kanalen'}
                    className={
                        'text-center flex justify-center items-center mr-10 text-bright-blue-800'
                    }
                >
                    <div className={'flex items-center'}>
                        <span className={'font-bold'}>KANALEN ZOEKEN</span>
                        <Components.Icons.Icon className={'w-14 h-14'} name={'chevron-right'} />
                    </div>
                </a>
            }

            {/*{*/}
            {/*    channels.meta && <Lists.Lazyload*/}
            {/*        loading={channels.isLoading}*/}
            {/*        load={channels.meta.current_page !== channels.meta.last_page}*/}
            {/*        onEnter={() => setParams({ ...params, page: channels.meta.current_page + 1 }, true)}*/}
            {/*        horizontal*/}
            {/*    />*/}
            {/*}*/}
        </Scroll>
    )
}
