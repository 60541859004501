import React, {useState} from "react";
import {Components, Functions} from 'eyecons-components'
import Requests from 'eyecons-requests'
import classNames from "classnames";

type PlayerShareProps = {
    video: Requests.Video
}

function Button({onClick, icon, color}: { onClick: () => void, icon: string, color: string }) {
    return (
        <button
            className={classNames(
                'w-10 h-10 flex items-center justify-center transition-all duration-200 ease mb-1.5 rounded-full text-white',
                color
            )}
            onClick={onClick}
        >
            <Components.Icons.Icon name={icon} className={'w-6 h-6'}/>
        </button>
    )
}

export function PlayerShare({video}: PlayerShareProps) {
    const [link, setLink] = useState(false)
    const [open, setOpen] = useState(false)

    const share_video = (type: string) => {
        let shareUrl = `${process.env.NEXT_PUBLIC_SITE_URL}/${video.url}`

        setOpen(false)
        Functions.share(type, shareUrl)
        if (type === 'copy') {
            setLink(true)
            setTimeout(() => setLink(false), 4000)
        }
    }

    return (
        <div className="absolute z-[998] top-4 right-4">
            <Button onClick={() => setOpen(!open)} icon={'reply'} color={open ? 'bg-reply/60' : 'bg-reply/40'}/>
            {
                open && <>
                    <Button onClick={() => share_video('facebook')} icon={'facebook'} color={'bg-facebook'}/>
                    <Button onClick={() => share_video('twitter')} icon={'x-white'} color={'bg-x-white'}/>
                    <Button onClick={() => share_video('whatsapp')} icon={'whatsapp'} color={'bg-whatsapp'}/>
                    <Button onClick={() => share_video('copy')} icon={'link'} color={'bg-link'}/>
                </>
            }

            <span
                className={classNames('absolute top-1 right-12 h-8 leading-8 px-2 inline-block whitespace-nowrap text-sm text-white mt-[-2rem] transition-all duration-150 ease-in-out delay-300', {
                    'opacity-0': !link,
                    'opacity-1': link
                })}>
            Link gekopieerd!
        </span>
        </div>
    )
}